import React from "react";
import { Link } from "react-router-dom";

const BlogContent = [
  {
    img: "blog262",
    title: `The Role of Technology in Modern Collaborative Workspaces`,
    author: "Sep 16, 2024",
    link: "/the-role-of-technology-in-modern-collaborative-workspaces",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog252",
    title: `How to Boost Team Collaboration in Shared Workspaces`,
    author: "Sep 09, 2024",
    link: "/how-to-boost-team-collaboration-in-shared-workspaces",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog242",
    title: `Productivity Tips for Working in a Shared Workspace`,
    author: "Sep 02, 2024",
    link: "/productivity-tips-for-working-in-a-shared-workspace",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog23-2",
    title: `Organizing Virtual Events and Meetups in Coworking Spaces`,
    author: "Aug 26, 2024",
    link: "/organizing-virtual-events-and-meetups-in-coworking-spaces",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog222",
    title: `The Role of Virtual Coworking Offices in Remote Work Trends`,
    author: "Aug 19, 2024",
    link: "/the-role-of-virtual-coworking-offices-in-remote-work-trends",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog21",
    title: `Tools and Technologies that Power Virtual Coworking Offices`,
    author: "Aug 12, 2024",
    link: "/tools-and-technologies-that-power-virtual-coworking-offices",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog20",
    title: `The Benefits of Virtual Coworking Offices for Freelancers`,
    author: "Aug 05, 2024",
    link: "/the-benefits-of-virtual-coworking-offices-for-freelancers",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog19",
    title: `Implementing and Managing a Virtual Office`,
    author: "July 29, 2024",
    link: "/implementing-and-managing-a-virtual-office",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog18-1",
    title: `The Rise of Virtual Offices in the Modern Workplace`,
    author: "July 22, 2024",
    link: "/the-rise-of-virtual-offices-in-the-modern-workplace",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog17",
    title: ` Innovative Shared Office Space Designs to Boost Creativity `,
    author: "July 15, 2024",
    link: "/innovative-shared-office-space-designs-to-boost-creativity",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog16",
    title: `Building a Strong Community in Shared Office Space`,
    author: "July 08, 2024",
    link: "/building-a-strong-community-in-shared-office-space",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog15-1",
    title: `How to Maximize Your Productivity in a Shared Office Space`,
    author: "July 01, 2024",
    link: "/how-to-maximize-your-productivity-in-a-shared-office-space",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog14",
    title: `Emerging Technologies Shaping the Future of Workspace`,
    author: "June 24, 2024",
    link: "/emerging-technologies-shaping-the-future-of-workspace",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog13",
    title: `Flexible Workspaces: Adapting to Changing Work Dynamics `,
    author: "June 17, 2024",
    link: "/flexible-workspaces-adapting-to-changing-work-dynamics",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "blog12-1",
    title: `Advanced IoT and Smart Office Automation Systems`,
    author: "June 10, 2024",
    link: "/iot-and-smart-office-solution",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "innovespace11blogsize",
    title: `Benefits of the Collaborative Workspace Environment`,
    author: "June 03, 2024",
    link: "/benefits-of-collaborative-workspaces",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "virtual",
    title: `Unlocking the Advantages of Virtual Offices for Businesses`,
    author: "May 27, 2024",
    link: "/unlocking-the-advantages-of-virtual-offices-for-bussiness",
    animationDealy: "200",
    alt: "img",
  },
  {
    img: "futureTrend",
    title: `Shaping the Future: Coworking in the New Work Era`,
    author: "May 20, 2024",
    link: "/Shaping-the-Future:-Coworking-in-the-New-Work-Era",
    animationDealy: "200",
    alt: "Future Trend",
  },
  {
    img: "coworkingspace",
    title: `Coworking vs. Traditional Office: Which is Right for Your Business ?`,
    author: "May 13, 2024",
    link: "/coworking-vs-traditional-office-which-is-right-for-your-business",
    animationDealy: "200",
    alt: "coworking business",
  },
  {
    img: "blognew",
    title: `Exploring the Gig Economy and Its Impact on Workspace Trends`,
    author: "Apr 22, 2024",
    link: "/blog-v7",
    animationDealy: "200",
    alt: "coworking business",
  },
  {
    img: "Churning",
    title: `Embracing Change: Gleaning Insights from the Churning of the Ocean`,
    author: "Oct 31, 2023",
    link: "/blog-page",
    animationDealy: "200",
    alt: "coworking business",
  },
  {
    img: "traditionals",
    title: `12 reasons Why you should choose Coworking Space over Traditional Offices`,
    author: "Sep 15, 2023",
    link: "/blog-v1",
    animationDealy: "200",
    alt: "coworking business",
  },
  {
    img: "hbr1",
    title: `Incubators vs. Accelerators Vs Pre-Accelerators – A Comparative Review`,
    author: "Aug 28, 2023",
    link: "/blog-v2",
    animationDealy: "",
    alt: "plug n play co working space",
  },
  {
    img: "walks",
    title: `The Walk – a movie `,
    author: "Aug 07, 2023",
    link: "/blog-v3",
    animationDealy: "100",
    alt: "coworking startup",
  },
  {
    img: "compas",
    title: `Amplify Revenue: Drive Income from FDI`,
    author: "July 20, 2023",
    link: "/blog-v4",
    animationDealy: "200",
    alt: "coworking community",
  },
  // {
  // img: "blog4",
  // title: `Incubators vs. Accelerators Vs Pre-Accelerators – A Comparative Review`,
  // author: "April 1, 2023",
  // link: "/blog-page",
  // animationDealy: "",
  // alt:"coworking workspace",
  // },
  // {
  // img: "blog1",
  // title: `The Walk – a movie `,
  // author: "Jun 23, 2023",
  // link: "/blog-page",
  // animationDealy: "100",
  // alt:"coworking space in",
  // },
];

const BlogInner = () => {
  return (
    <>
      {BlogContent.map((val, i) => (
        <div
          className="col-lg-5 mt-40 otherservicespadding"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDealy}
          key={i}
        >
          <div className="feature-article-meta">
            <div className="img-meta">
              <img src={`images/blog/${val.img}.jpg`} alt={val.alt} />
            </div>
            <div className="post-meta">
              <div className=" mt-20">
                {val.author}. <span>{val.designation}</span>
              </div>

              <Link to={val.link} className="titleblog">
                {val.title}
              </Link>
            </div>
          </div>
          {/* /.feature-article-meta */}
        </div>
      ))}
    </>
  );
};

export default BlogInner;
