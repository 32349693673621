import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Slide } from "@material-ui/core";
import { date } from "yup";

const BlogContent = [
  {
    img: "blog26.jpg",
    date: "Sep 16, 2024",
    title: `The Role of Technology in Modern Collaborative Workspaces`,
    desc: `As organizations continue to evolve, so do the ways in which teams work together to achieve common goals. One of the most significant changes in recent years has been the rise of technology`,
    routePath: "/the-role-of-technology-in-modern-collaborative-workspaces",
  },
  {
    img: "blog25.jpg",
    date: "Sep 09, 2024",
    title: `How to Boost Team Collaboration in Shared Workspaces`,
    desc: `In today’s dynamic work environment, shared workspaces have become a popular choice for businesses of all sizes. These spaces offer flexibility, cost-efficiency, and a community-driven`,
    routePath: "/how-to-boost-team-collaboration-in-shared-workspaces",
  },
  {
    img: "blog24.jpg",
    date: "Sep 02, 2024",
    title: `Productivity Tips for Working in a Shared Workspace`,
    desc: `Shared workspaces, also known as coworking spaces, have become increasingly popular for freelancers, startups, and even established businesses.They offer flexibility, networking opportunities`,
    routePath: "/productivity-tips-for-working-in-a-shared-workspace",
  },
];

const BlogStyle3 = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {BlogContent.map((item, i) => (
        <div className="post-meta" key={i}>
          <div className="imgsetup">
            <img
              src={`images/assets/${item.img}`}
              alt="media"
              className="image-meta1"
            />
          </div>
          <div className="post">
            <div className="date">{item.date}</div>
            <h3>
              <Link to={item.routePath} className="title">
                {item.title}
              </Link>
            </h3>
            <p>{item.desc}</p>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Learn More</span>
              {/* <i className="flaticon-right-arrow"></i> */}
            </Link>
          </div>
          {/* End post */}
        </div>
        // /.post-meta
      ))}
    </>
  );
};

export default BlogStyle3;
